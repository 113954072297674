<template>
    <div>
        <h2>not found</h2>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
});
</script>

<style scoped>

</style>
